/**
 * 校验中国大陆手机号
 * @param phoneNumber - 需要校验的手机号
 * @returns 是否为有效的手机号
 */
export function validatePhoneNumber(phoneNumber: string): boolean {
    // 中国大陆手机号正则表达式
    const phoneRegex = /^(?:\+86)?1[3-9]\d{9}$/;
    return phoneRegex.test(phoneNumber);
}



export function getMarkDownHeadings(markdown: string): Heading[] {
    const headingRegex = /^(#+\s)(.*)/gm;
    const linkRegex = /\[([^\]]+)\]\([^\)]+\)/;
    let match;
    const headings: Heading[] = [];

    while ((match = headingRegex.exec(markdown))) {
        let title = match[2];

        // Remove markdown link syntax if present
        const linkMatch = title.match(linkRegex);
        if (linkMatch) {
            title = linkMatch[1];
        }

        headings.push({ level: match[1].length, title: title });
    }

    return headings;
}


export function image(url: string, size: number) {
    return `${url}?x-oss-process=image/resize,w_${size}`
}

export function oss(url: string) {
    return `${import.meta.env.VITE_OSS_URL}/${url}`
}